@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  overflow-x: hidden;
}

code {
  font-family: 'Poppins', sans-serif;
}

.bg-grain {
  background-image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill-opacity="0.1"><rect width="1" height="1" fill="black" x="5" y="5"></rect></svg>');
  background-size: 4px 4px;
  opacity: 0.3;
  mix-blend-mode: multiply;
}

/* Grainy effect overlay */
.grain-animation {
  background: url("/Users/adnancobanoglu/horusmedia_v2/src/Assets/bg.png") repeat;
  animation: grainy-move 1s infinite;
  opacity: 0.2; /* Adjust for intensity */
  mix-blend-mode: overlay; /* Makes it blend with the background */
}

/* Grainy movement */
@keyframes grainy-move {
  0% {
      transform: translate(0, 0);
  }
  25% {
      transform: translate(2px, -2px);
  }
  50% {
      transform: translate(-2px, 2px);
  }
  75% {
      transform: translate(2px, 2px);
  }
  100% {
      transform: translate(0, 0);
  }
}

.progress {
  position: fixed;
  left: 0;
  right: 0;
  height: 5px;
  background: var(--accent);
  bottom: 100px;
}

.parallax {
  overflow: hidden;
  letter-spacing: -2px;
  line-height: 0.8;
  margin: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
}

.parallax .scroller {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 64px;
  display: flex;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
}

.parallax span {
  display: block;
  margin-right: 30px;
}

